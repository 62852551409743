<script>
import PartnerTreeMenu from '@/components/partner/tree-menu'

export default {
  props: {
    user: Object,
    searchByUser: Boolean,
  },
  components: {
    PartnerTreeMenu
  },
  data() {
    return {
      year: '',
      list: {},
      dailyList: {},
      totalDeposit: 0,
      totalWithdraw: 0,
      totalDailyDeposit: 0,
      totalDailyWithdraw: 0,
      parent: null,
      includeBelow: false
    }
  },
  mounted() {
    if (this.searchByUser === false) {
      this.$refs.partnerTreeMenu.getPartners().then()
    } else {
      this.searchAll()
    }
    this.year = new Date().getFullYear()
  },
  methods: {
    setParent: function(id) {
      this.parent = id
    },
    searchAll: function() {
      if (this.user) this.parent = this.user.id
      if (this.searchByUser === true && !this.user) return false

      const self = this
      this.searchList().then(function(result) {

        // 금일 통계 데이터
        const t = new Date()
        const year = t.getFullYear().toString()
        const month = (t.getMonth()+1).zf(2)
        const date = year + '-' + month

        if (result !== false) {
          self.getMonthlyCashStat(date).then()
        }
      })
    },
    searchList: async function () {
      if (this.searchByUser === true && !this.user) return false
      const loader = this.$loading.show({ container: this.$refs.yearlyContainer})
      try {
        const result = await this.$API.statistic.getSubCashYearly({year: this.year, userId: this.parent, includeBelow: this.includeBelow})
        if (result.code !== 0) {
          return alert(result.msg)
        }
        this.list = {}
        this.totalDeposit = 0
        this.totalWithdraw = 0

        for(const key in result.documents) {
          const record = result.documents[key]
          this.totalDeposit += record.deposit.sum
          this.totalWithdraw += record.withdraw.sum
          this.list[key] = record
        }

        return true
      } finally {
        loader.hide()
      }
    },
    getMonthlyCashStat: async function(date) {
      if (this.searchByUser === true && !this.user) return
      const loader = this.$loading.show({ container: this.$refs.monthlyContainer})
      try {
        const result = await this.$API.statistic.getSubCashMonthly({ date, userId: this.parent, includeBelow: this.includeBelow })
        if (result.code !== 0) {
          return alert(result.msg)
        }
        this.dailyList = {}
        this.totalDailyDeposit = 0
        this.totalDailyWithdraw = 0

        for(let key in result.documents) {
          const record = result.documents[key]
          this.totalDailyDeposit += record.deposit.sum
          this.totalDailyWithdraw += record.withdraw.sum
          this.dailyList[key] = record
        }
      } finally {
        loader.hide()
      }
    }
  },
  watch: {
    user: function(val) {
      if (val) {
        this.searchAll()
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <form class="header-search" @submit.prevent="searchList">
          <!-- 페이징 및 검색 //-->
          <div class="row">
            <div class="col-md-7"></div>
            <div class="col-md-2">
              <b-form-checkbox v-model="includeBelow" switch size="lg" @change="searchList">
                하부포함
              </b-form-checkbox>
            </div>
            <div class="col-md-3 mb-2">
              <div class="form-group mb-2">
                <select class="form-select" v-model="year" @change="searchList">
                  <option value="2022">2022년</option>
                  <option value="2023">2023년</option>
                  <option value="2024">2024년</option>
                  <option value="2025">2025년</option>
                  <option value="2026">2026년</option>
                  <option value="2027">2027년</option>
                  <option value="2028">2028년</option>
                  <option value="2029">2029년</option>
                  <option value="2030">2030년</option>
                </select>
              </div>
            </div>
          </div>
        </form>

        <hr class="mt-0 mb-3"/>

        <div class="w-100">
          <div class="d-md-flex">

            <!-- 상위 목록 //-->
            <partner-tree-menu :partner="$store.getters['auth/user']" @setParent="setParent" @searchList="searchAll" ref="partnerTreeMenu" v-if="searchByUser === false"></partner-tree-menu>

            <!-- 목록 //-->
            <div class="w-100">
              <div class="card">
                <div class="card-body">
                  <h5>{{ $t('월별 통계') }}</h5>
                  <div class="table-responsive mb-0 rounded-3" ref="yearlyContainer">
                    <table class="table align-middle table-sm table-bordered table-nowrap table-hover table-striped">
                      <colgroup>
                        <col style="width: 25%;"/>
                        <col style="width: 25%;"/>
                        <col style="width: 25%;"/>
                        <col style="width: 25%;"/>
                      </colgroup>
                      <thead class="table-dark">
                      <tr>
                        <th>{{ $t('월') }}</th>
                        <th>{{ $t('충전') }}</th>
                        <th>{{ $t('환전') }}</th>
                        <th>{{ $t('잔액') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(record, index) in list" :key="'stat-cash-' + index">
                        <td><a href="javascript:void(0)" @click="getMonthlyCashStat(index)">{{ index }}</a></td>
                        <td class="text-end">{{ record.deposit.sum.formatThousands() }}</td>
                        <td class="text-end">{{ record.withdraw.sum.formatThousands() }}</td>
                        <td class="text-end">{{ (record.deposit.sum - record.withdraw.sum).formatThousands() }}</td>
                      </tr>
                      </tbody>
                      <tfoot class="table-dark">
                      <tr>
                        <th>{{ $t('합계') }}</th>
                        <th class="text-end">{{ totalDeposit.formatThousands() }}</th>
                        <th class="text-end">{{ totalWithdraw.formatThousands() }}</th>
                        <th class="text-end">{{ (totalDeposit - totalWithdraw).formatThousands() }}</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>

                  <h5>{{ $t('일별 통계') }}</h5>
                  <div class="table-responsive mb-0 rounded-3" ref="monthlyContainer">
                    <table class="table align-middle table-sm table-bordered table-nowrap table-hover table-striped">
                      <colgroup>
                        <col style="width: 25%;"/>
                        <col style="width: 25%;"/>
                        <col style="width: 25%;"/>
                        <col style="width: 25%;"/>
                      </colgroup>
                      <thead class="table-dark">
                      <tr>
                        <th>{{ $t('일') }}</th>
                        <th>{{ $t('충전') }}</th>
                        <th>{{ $t('환전') }}</th>
                        <th>{{ $t('잔액') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(record, index) in dailyList" :key="'stat-cash-' + index">
                        <td>{{ index }}</td>
                        <td class="text-end">{{ record.deposit.sum.formatThousands() }}</td>
                        <td class="text-end">{{ record.withdraw.sum.formatThousands() }}</td>
                        <td class="text-end">{{ (record.deposit.sum - record.withdraw.sum).formatThousands() }}</td>
                      </tr>
                      </tbody>
                      <tfoot class="table-dark">
                      <tr>
                        <th>{{ $t('합계') }}</th>
                        <th class="text-end">{{ totalDailyDeposit.formatThousands() }}</th>
                        <th class="text-end">{{ totalDailyWithdraw.formatThousands() }}</th>
                        <th class="text-end">{{ (totalDailyDeposit - totalDailyWithdraw).formatThousands() }}</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
